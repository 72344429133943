/* eslint-disable no-undef */
import './index.scss';

import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import ToggleBetweenValues from '../../components/toggle-between-values';
import ToggleCartEmpty from '../../components/toggle-theme-cart-empty';
import LocationInput from '../../components/location-input';
import NavMenu from '../../components/nav-menu';
import MobileHeader from '../mob-header';
import LoaderBackDrop from '../../components/loader-backdrop';
// import OffersToast from '../../components/offers-toast';
import LocationRestriction from '../../components/location-restriction-popover';

import Login from '../../pages/login';
import OnboardPage from '../../pages/onboarding';
import Account from '../../pages/account';
// import LOGO from '../../../assets/images/splash_screen_img.png';
import LOGO from '../../../assets/images/logo.png';
import RestaurantFinderIcon from '../../../assets/images/dine-in-black.svg';
// import BKLOGO from '../../../assets/images/bkLogo.png';
// import BKWALLOGO from '../../../assets/images/bk-wall.jpg';
import LOGINLOGO from '../../../assets/images/login-inactive.svg';
import CARTLOGO from '../../../assets/images/cart.svg';
import OFFERSLOGO from '../../../assets/images/offers/offers-inactive.svg';

import {
  getSearchImage,
  getCartActiveImage,
  getLoginActiveImage,
  getOfferActiveImage,
  // getBkWallActiveImage,
  getRestaurantFinderImage
} from '../../utils/imageChanges';
import { ROUTES as _routes, THEME } from '../../constants/app-constants';
import {
  LocalStorage,
  SessionStorage
} from '../../burger-king-lib/utils/storage.web';
import { LocalSessionKey } from '../../burger-king-lib/constants/app-constants';
import {
  clearRedisCart
  // getOutletChange1
} from '../../burger-king-lib/redux/redisCart/actions';
import {
  getHomeApiList,
  getToken
} from '../../burger-king-lib/redux/home/actions';
import { getUserDetail } from '../../burger-king-lib/redux/login/actions';
import {
  deliveryAddressStorage,
  restaurantAddressStorage,
  getCartItemCountAndPrice1
} from '../../utils/services';
import { homeApiListPayload } from '../../payload-handling/home';
import {
  getOutletChange,
  getOutletChange1
} from '../../burger-king-lib/redux/cart/actions';
// import { getCartItemPayload } from '../../payload-handling/cart';
// import { Analytics } from '../../helpers/analytics';

const { RESTAURANT, DELIVERY } = THEME;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: {},
      cartData: {},
      showOnboardModal: false,
      showLogin: false,
      showToggleEmptyCartModal: false,
      showHamburgerMenu: false,
      showBackDrop: false,
      showNoInternetAlert: false,
      isLoggedIn: LocalStorage.get(LocalSessionKey.USER_DATA) || false
    };
    this.navMenu = [
      {
        name: 'Nearby Stores',
        iconpath: RestaurantFinderIcon,
        key: '/store-locator',
        onClick: this.bkWallOnClick,
        activeIconPath: getRestaurantFinderImage
      },
      {
        name: 'King Deals',
        iconpath: OFFERSLOGO,
        activeIconPath: getOfferActiveImage,
        key: '/offers',
        onClick: this.OffersOnClick
      },
      {
        name: 'login',
        iconpath: LOGINLOGO,
        activeIconPath: getLoginActiveImage,
        key: '/account',
        onClick: this.loginOnClick
      },
      {
        name: 'Cart',
        iconpath: CARTLOGO,
        activeIconPath: getCartActiveImage,
        key: '/cart',
        onClick: this.cartOnClick
      }
    ];
    this.isLocationChanged = false;
    const { location, theme } = this.props;

    if (!location.search.includes('table_id')) {
      setTimeout(() => {
        if (location.pathname === '/cart-offers' && theme !== 'delivery') {
          this.getHomeApiList();
        }
      }, 1000);
    }
    if (
      !location.search.includes('table_id') &&
      !location.search.includes('store_id') &&
      !location.search.includes('lat') &&
      this.props.theme === 'delivery'
    ) {
      this.getAddress();
    }
  }

  checkAndUpdateLocation() {
    const { location } = this.props;
    const latLngExists = window.localStorage.getItem('LAT_LNG');
    const isTheatreMode = location?.search?.includes('theatre_data');

    if (!latLngExists && !isTheatreMode) {
      this.getCurrentLocation();
    }
  }

  componentDidMount() {
    if (!(window && window.localStorage && localStorage.getItem('TOKEN')))
      this.getTokenData();
    this.checkAndUpdateLocation();
    window.addEventListener('offline', this.handleConnectionChange);
    window.addEventListener('online', this.handleConnectionChange);
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.theme !== this.props.theme) {
      if (this.props.theme === 'delivery') {
        this.getAddress();
      }
    }
    const { cartData, homeData, loginData } = this.props;
    // const { value } = this.state;
    if (
      loginData &&
      loginData.userDetail &&
      loginData.userDetail.content &&
      loginData.userDetail.content.length > 0
    ) {
      SessionStorage.set(
        'IS_COD_BLOCK',
        loginData.userDetail.content[0].is_cod_blocked
      );
    }

    if (
      cartData &&
      cartData !== prevProps.cartData &&
      cartData.postCart &&
      cartData.postCart !== prevProps.cartData.postCart
    ) {
      if (cartData.postCart.content) {
        this.setCartItem(cartData.postCart.content);
      } else if (
        cartData.postCart.error === 1 &&
        cartData.postCart.message === 'Your cart is empty.'
      ) {
        this.setCartItem([]);
      }
    }

    if (
      cartData &&
      cartData !== prevProps.cartData &&
      cartData.clearCart !== prevProps.cartData.clearCart
    ) {
      if (cartData.clearCart.error === 0) {
        this.onSuccessCleaCart();
      } else if (cartData.clearCart.error && cartData.clearCart.message) {
        window.alert(cartData.clearCart.message);
      }
    }
    if (
      homeData.homeApiList &&
      homeData.homeApiList !== prevProps.homeData.homeApiList
    ) {
      if (homeData.homeApiList.message) {
        this.hideBackDrop();
      }
      if (homeData.homeApiList.content) {
        if (homeData.homeApiList.content.outlet_info) {
          const info = homeData.homeApiList.content.outlet_info;
          info.location = `${info.outlet_name}, ${info.address}`;
          this.setValue(info);
        }
        if (
          homeData.homeApiList.content.is_outlet_active === false ||
          homeData.homeApiList.content.is_outlet_active === true
        ) {
          this.setOutletData(
            homeData.homeApiList.content.outlet_status,
            homeData.homeApiList.content.outlet_status_header,
            homeData.homeApiList.content.is_outlet_active
          );
        }
      }
    }

    if (homeData && homeData.data !== prevProps.homeData.data) {
      if (homeData && homeData.data && homeData.data.content) {
        LocalStorage.set(
          LocalSessionKey.USER_ID,
          homeData.data.content.user_id
        );
        LocalStorage.set(LocalSessionKey.TOKEN, homeData.data.content.token);
        LocalStorage.set(
          LocalSessionKey.REFRESH_TOKEN,
          homeData.data.content.refresh_token
        );
        const { getUserDetailData } = this;
        const userId = LocalStorage.get(LocalSessionKey.USER_DATA);
        if (!!userId) getUserDetailData();
      }
    }

    if (
      cartData &&
      cartData.outletChange &&
      cartData.outletChange !== prevProps.cartData.outletChange &&
      cartData.outletChange.content &&
      cartData.outletChange.content.outlet_id
    ) {
      const res = await deliveryAddressStorage.get();
      this.setInputValue(res);
    }
  }

  setInputValue = async value => {
    await this.setState({ value });
  };

  handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'offline') {
      this.setState({ showNoInternetAlert: true });
    } else {
      this.setState({ showNoInternetAlert: false });
    }
  };

  getTokenData = () => {
    const { getTokenData } = this.props;
    getTokenData();
  };

  getHomeApiList = () => {
    const { getHomeApiListData, theme } = this.props;
    const payload = homeApiListPayload(theme);

    SessionStorage.set('ToastShowedOnce', 0);
    getHomeApiListData(payload);
  };

  setOutletData = (value, head, active) => {
    if (active) {
      if (SessionStorage.get('Store_Status')) {
        SessionStorage.remove('Store_Status');
      }
    } else {
      const status = {
        title: head,
        status: value
      };
      SessionStorage.set('Store_Status', status);
    }
  };

  getUserDetailData = () => {
    const { getUserDetailData } = this.props;
    const userId = LocalStorage.get(LocalSessionKey.USER_DATA);
    if (!!userId) getUserDetailData();
    this.nameHandler();
  };

  getCurrentLocation = () => {
    const { setLatLng } = this;

    if (window && window.navigator && window.navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          if (
            position &&
            position.coords &&
            position.coords.latitude &&
            position.coords.longitude
          ) {
            const json = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            const { google } = window;
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode(
              { latLng: json },
              (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                  if (
                    results &&
                    results.length &&
                    results[1] &&
                    results[1].formatted_address
                  ) {
                    let pincode = '';
                    results[1].address_components.forEach(data => {
                      if (data && data.types) {
                        const filter = data.types.filter(
                          item => item === 'postal_code'
                        );
                        if (filter && filter.length) pincode = data.long_name;
                      }
                      return data;
                    });
                    // eslint-disable-next-line no-lone-blocks
                    const { getOutletChangeData } = this.props;
                    const value = {
                      location: results[1].formatted_address,
                      pincode,
                      latitude: json.lat,
                      longitude: json.lng
                    };
                    this.setState({
                      value,
                      showOnboardModal: false
                    });
                    getOutletChangeData(value, { page: 'header - GeoCode' });
                    const fbscript = document.createElement('script');
                    fbscript.src = '//connect.facebook.net/en_US/fbevents.js';
                    fbscript.onload = () => {
                      fbq('track', 'FindLocation', {
                        location: results[1].formatted_address,
                        lat: json.lat,
                        long: json.lng
                      });
                    };
                    document.head.appendChild(fbscript);
                  }
                }
              },
              this
            );
            setLatLng(json);
          } else {
            console.log('No Positions found');
          }
          // eslint-disable-next-line no-unused-vars
        },
        error => {
          alert('Unable to fetch the location. Please enter manually');
          this.getAddress();
        }
      );
    } else {
      alert('Unable to fetch the location. Please enter manually');
      this.getAddress();
    }
  };

  getAddress = () => {
    const latLng = LocalStorage.get(LocalSessionKey.LAT_LNG);
    if (latLng && latLng.lat && latLng.lng) {
      const json = {
        lat: latLng.lat,
        lng: latLng.lng
      };
      const { google } = window;
      if (google && google.maps && google.maps.version) {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode(
          { latLng: json },
          (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              if (
                results &&
                results.length &&
                results[1] &&
                results[1].formatted_address
              ) {
                let pincode = '';
                results[1].address_components.forEach(data => {
                  if (data && data.types) {
                    const filter = data.types.filter(
                      item => item === 'postal_code'
                    );
                    if (filter && filter.length) pincode = data.long_name;
                  }
                  return data;
                });
                const value = {
                  location: results[1].formatted_address,
                  pincode,
                  latitude: json.lat,
                  longitude: json.lng
                };
                const { getOutletChangeData } = this.props;
                this.setState({
                  value
                });
                getOutletChangeData(value, { page: 'header - getaddress' });
                deliveryAddressStorage.set({
                  location: results[1].formatted_address,
                  pincode,
                  latitude: json.lat,
                  longitude: json.lng
                });
              }
            }
          },
          this
        );
      }
    } else {
      let { showOnboardModal } = this.state;
      const LocationShowedOnce = SessionStorage.get('LocationShowedOnce');
      if (!LocationShowedOnce) {
        SessionStorage.set('LocationShowedOnce', 1);
        showOnboardModal = true;
      }
      this.setState({ value: {}, showOnboardModal });
    }
  };

  setValue = setvalue => {
    // this.setState({ value });
    restaurantAddressStorage.set(setvalue);
    SessionStorage.set(LocalSessionKey.RESTAURANT_DETAIL, setvalue);
    SessionStorage.set(LocalSessionKey.OUTLET_ID, setvalue.outlet_id);

    if (this.isLocationChanged) {
      // Refresh the page
      const { themeHandler, theme } = this.props;
      themeHandler(theme);
      this.isLocationChanged = false;
    }
  };

  setLatLng = json => {
    this.latLng = json;
    LocalStorage.set(LocalSessionKey.LAT_LNG, json);
    this.getHomeApiList();
  };

  setCartItem = data => {
    this.setState({
      cartData: getCartItemCountAndPrice1(data),
      showToggleEmptyCartModal: false
    });
  };

  onSuccessCleaCart = () => {
    this.setState({ showToggleEmptyCartModal: false });
  };

  hideBackDrop = () => {
    this.setState({ showBackDrop: false });
  };

  onChange = e => {
    const { cartData } = this.state;
    const { theme } = this.props;
    if (cartData && cartData.price) {
      this.setState({ showToggleEmptyCartModal: true });
    } else {
      const { themeHandler } = this.props;
      const newTheme = e;

      if (newTheme === 'dinein' || newTheme === 'takeaway') {
        if (theme !== 'takeaway') {
          themeHandler('takeaway');
        }
        const { location } = this.props;
        if (
          !location.search.includes('table_id') &&
          !location.search.includes('store_id') &&
          !location.search.includes('lat') &&
          theme === 'takeaway'
        ) {
          this.getAddress();
        }
      } else if (newTheme === 'delivery') {
        themeHandler('delivery');
        this.getCurrentLocation();
      }

      const { history } = this.props;
      const { location } = history;
      const { pathname } = location;
      if (
        pathname !== _routes.HOME &&
        pathname !== _routes.OFFERS &&
        pathname !== _routes.MY_VOUCHERS
      ) {
        history.push(_routes.HOME);
      }
    }
  };

  hamburgerClick = () => {
    const { history } = this.props;
    history.push(_routes.HOME);
  };

  searchClick = () => {
    const { history } = this.props;
    history.push(_routes.SEARCH);
  };

  cartOnClick = key => {
    if (key === '/cart') {
      const { cartData } = this.state;
      if (cartData && cartData.quantity) {
        const script = document.createElement('script');
        script.src = '//cdnt.netcoresmartech.com/smartechclient.js';
        script.onload = () => {
          smartech('dispatch', 'af_vieworder', {
            item_count: cartData.quantity,
            order_total: cartData.price,
            page_url: 'https://www.burgerking.in/cart'
          });
        };
        document.head.appendChild(script);

        const { history } = this.props;
        history.push(_routes.CART);
      } else {
        alert('No items are added');
      }
    } else {
      alert('Hey! Feature coming soon. stay tuned.');
    }
  };

  loginOnClick = () => {
    const isLoggedIn = LocalStorage.get(LocalSessionKey.USER_DATA);

    if (!isLoggedIn) {
      this.setState({ showLogin: true });
    } else {
      const { history } = this.props;
      const { innerWidth } = window;
      if (innerWidth > 1023) history.push(_routes.RECENT_ORDERS);
      else this.setState({ showHamburgerMenu: true });
    }
  };

  nameHandler = () => {
    const isLoggedIn = LocalStorage.get(LocalSessionKey.USER_DATA);
    if (!isLoggedIn) {
      return 'Login';
    }
    return 'My Profile';
  };

  OffersOnClick = key => {
    const { history } = this.props;
    history.push(key);
    // alert('Hey! Feature coming soon. stay tuned.');
  };

  bkWallOnClick = key => {
    const { history } = this.props;
    history.push(key);
    // alert('Hey! Feature coming soon. stay tuned.');
  };

  toggleDisplayOnBoardModal = () => {
    const { showOnboardModal } = this.state;
    this.setState({ showOnboardModal: !showOnboardModal });
  };

  addressChangeHandler = value => {
    const { isLoggedIn } = this.state;
    const { theme } = this.props;
    if (value && value.location) {
      this.setState({ value });
      const { getOutletChangeData } = this.props;
      getOutletChangeData(value, { page: 'header - addresschange' });
      if (theme !== RESTAURANT) deliveryAddressStorage.set(value);
      const { history } = this.props;
      const { location } = history;
      const { pathname } = location;
      this.isLocationChanged = true;
      if (pathname === _routes.HOME) {
        this.setState({ showBackDrop: true });
        this.getHomeApiList();
      }
      this.getHomeApiList();
    }
  };

  onClickLocation = () => {
    this.toggleDisplayOnBoardModal();
  };

  onLoginSuccessHandler = () => {
    this.getHomeApiList();
    this.setState({ showLogin: false });
  };

  loggedInToggleHandler = () => {
    const { isLoggedIn } = this.state;
    this.setState({ isLoggedIn: !isLoggedIn });
  };

  confirmToggleChangeHandler = () => {
    const { getClearCartData, theme, themeHandler } = this.props;
    // alert(theme);
    this.setState({ showToggleEmptyCartModal: false, cartData: [] });
    if (theme === 'delivery') {
      themeHandler('takeaway');
    } else {
      const { location } = this.props;
      themeHandler('delivery');
      if (
        !location.search.includes('table_id') &&
        !location.search.includes('store_id') &&
        !location.search.includes('lat') &&
        this.props.theme === 'delivery'
      ) {
        this.getAddress();
      }
    }
    getClearCartData();
    const { history } = this.props;
    const { location } = history;
    const { pathname } = location;
    if (
      pathname !== _routes.HOME &&
      pathname !== _routes.OFFERS &&
      pathname !== _routes.MY_VOUCHERS
    ) {
      history.push(_routes.HOME);
    }
  };

  cancelToggleChangeHandler = () => {
    this.setState({ showToggleEmptyCartModal: false });
  };

  hideHamburgerMenu = () => {
    this.setState({ showHamburgerMenu: false });
  };

  closeAlertModal = () => {
    const condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') this.setState({ showNoInternetAlert: false });
  };

  render() {
    const {
      value,
      cartData,
      showOnboardModal,
      showLogin,
      showToggleEmptyCartModal,
      showHamburgerMenu,
      showBackDrop,
      showNoInternetAlert,
      isLoggedIn
    } = this.state;
    const { theme, history, themeHandler, showDineinTheatre } = this.props;
    const { navMenu } = this;
    const { innerWidth } = window;
    const { pathname } = history.location;
    const desktopHeaderDom = (
      <div className="header">
        <div
          className="header__logo u_cursor_pointer"
          role="presentation"
          onClick={() => this.hamburgerClick()}
        >
          <img
            src={LOGO}
            alt="logo"
            className="header__logo-destop"
            width="79"
            height="79"
          />
        </div>

        <div className="header__user-choice">
          <ToggleBetweenValues
            theme={theme}
            leftContent="delivery"
            rightContent="dine-in/takeaway"
            onChange={this.onChange}
            value={theme}
            checked={theme === 'dinein' || theme === 'takeaway' || false}
          />
          <div className="header__divider" />
          <LocationInput
            onClickLocation={this.onClickLocation}
            value={value && value.location}
            theme={theme}
            readOnly
          />
        </div>
        <NavMenu
          theme={theme}
          data={navMenu}
          activeMenu={pathname}
          count={(cartData && cartData.quantity) || 0}
        />

        <div
          className="header__search u_cursor_pointer"
          role="presentation"
          onClick={() => this.searchClick()}
        >
          <img src={getSearchImage(theme)} alt="search" />
        </div>
      </div>
    );
    return (
      <>
        {innerWidth > 1023 ? (
          desktopHeaderDom
        ) : (
          <MobileHeader
            loginOnClick={this.loginOnClick}
            showDineinTheatre={showDineinTheatre}
            theme={theme}
            onChange={this.onChange}
            value={value}
            onClickLocation={this.onClickLocation}
          />
        )}
        {showOnboardModal && (
          <OnboardPage
            theme={theme}
            themeHandler={themeHandler}
            toggleDisplayOnBoardModal={this.toggleDisplayOnBoardModal}
            addressChangeHandler={this.addressChangeHandler}
          />
        )}
        {showLogin ? (
          <Login
            theme={theme}
            onLoginSuccessHandler={this.onLoginSuccessHandler}
            loggedInToggleHandler={this.loggedInToggleHandler}
          />
        ) : (
          ''
        )}
        {showToggleEmptyCartModal ? (
          <ToggleCartEmpty
            onConfirmClick={this.confirmToggleChangeHandler}
            onCancelClick={this.cancelToggleChangeHandler}
            theme={theme}
          />
        ) : (
          ''
        )}
        {showBackDrop ? <LoaderBackDrop theme={theme} /> : ''}

        <div
          className={`header__hamburger header__hamburger_${
            showHamburgerMenu ? 'show' : 'hide'
          }`}
        >
          <Account
            theme={theme}
            hideHamburgerMenu={() => this.hideHamburgerMenu()}
          />
        </div>
        {showNoInternetAlert ? (
          <LocationRestriction
            title="You are offline"
            description="Try reconnecting to the internet to continue using the app"
            okayClick={this.closeAlertModal}
            theme={theme}
          />
        ) : (
          ''
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    cartData: state.redisCart,
    homeData: state.home,
    loginData: state.login
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getClearCartData: payloadData => dispatch(clearRedisCart(payloadData)),
    getUserDetailData: payloadData => dispatch(getUserDetail(payloadData)),
    getHomeApiListData: payloadData => dispatch(getHomeApiList(payloadData)),
    getTokenData: payloadData => dispatch(getToken(payloadData)),
    getOutletChangeData: (payloadData, meta) =>
      dispatch(getOutletChange1(payloadData, meta))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
